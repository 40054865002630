import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingIndicatorComponent } from "./shared/components/loading/loading.component";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	providers: [],
	imports: [
		RouterModule,
		LoadingIndicatorComponent,
	],
})
export class AppComponent {
	title = 'Softrans';
}
